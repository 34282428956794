import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)購物車列表
    getQuotaList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/quota', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)會員購物車列表
    getCustomerQuotaList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}/quota`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
