<template>
  <div>
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              點數紀錄
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
            <!-- per-page-selector d-inline-block   -->
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getQuotaListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 狀態 -->
        <template #cell(type)="data">
          <div class="table-col">
            <b-badge
              :variant="resolveType(data.item).variant"
            >
              <!-- @click="linkService(data.item)" -->
              {{ resolveType(data.item).text }}
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 訂單 -->
        <template #cell(order)="data">
          <div class="table-col">
            <div
              v-if="data.item.system_order_id"
              class="d-block text-nowrap selection-group d-flex align-items-center"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                :to="{ name: 'admin-order-view', params: { id: data.item.order_info.id } }"
              >
                {{ data.item.system_order_id }}
              </b-link>
              <span
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                class="selection-btn-icon-show"
              >
                <b-img
                  src="/dashboard/admin/images/table/external-link.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </span>
            </div>

            <div
              v-else
              class="text-nowrap"
            >
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 餘額 -->
        <template #cell(before_quota)="data">
          <div class="table-col text-nowrap">
            <div
              v-if="data.item.before_quota"
              class="d-flex align-items-center"
            >
              ${{ parseInt(data.item.before_quota, 10).toLocaleString() }}
              <feather-icon
                size="16"
                icon="ArrowRightIcon"
                class="mx-50"
              />
              ${{ parseInt(data.item.before_quota + data.item.point, 10).toLocaleString() }}
            </div>
          </div>
        </template>

        <!-- 欄位: 點數 -->
        <template #cell(point)="data">
          <div
            class="table-col text-nowrap d-flex"
            @click="onSubmitRemark(data.item)"
          >
            <span
              v-if="parseInt(data.item.point, 10) <= 0"
              class="text-danger font-weight-bolder  point-price"
            >
              -${{ parseInt(data.item.point * -1, 10).toLocaleString() }}
            </span>

            <span
              v-else
              class="text-success font-weight-bolder point-price"
            >
              +${{ parseInt(data.item.point, 10).toLocaleString() }}
            </span>

            <div v-if="data.item.remark_display">
              <b-img
                src="/dashboard/admin/images/table/chat-remark.svg"
                height="20"
                width="20"
                class="cursor-pointer mb-25 ml-50"
              />
            </div>
          </div>
        </template>

        <!-- 欄位: 會員名稱 -->
        <template #cell(customer)="data">
          <div class="table-col">
            <b-media
              v-if="data.item.customer"
              vertical-align="center"
            >
              <template #aside>
                <b-link :to="{ name: 'admin-customer-view', params: { id: data.item.customer_info.id } }">
                  <b-avatar
                    size="32"
                    :src="data.item.customer_info.image"
                    :text="avatarText(data.item.customer_info.name)"
                    variant="light-primary"
                  />
                </b-link>
              </template>
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  :to="{ name: 'admin-customer-view', params: { id: data.item.customer_info.id } }"
                >
                  {{ data.item.customer_info.name }} {{ data.item.customer_info.family_name }}
                </b-link>
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選"
                  class="selection-btn-icon-show"
                  @click="searchCol({
                    name: '會員SSID',
                    key: 'search_query',
                    value: data.item.customer,
                  })"
                >
                  <b-img
                    src="/dashboard/admin/images/table/filter2.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
              </div>
              <div
                class="text-muted item-description-email"
              >
                <!-- @click="selectedEditRow(data.item)" -->
                {{ data.item.customer_info.account }}
              </div>
            </b-media>

            <div
              v-else
              class="text-nowrap"
            >
              <b-avatar
                size="32"
                variant="light-primary"
              />
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 備註 -->
        <template #cell(content)="data">
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          <div class="table-col show-text table-title">{{ data.item.content ? data.item.content : '---' }}</div>
        </template>

        <!-- 欄位: 操作者 -->
        <template #cell(creator)="data">
          <div class="table-col">
            <div
              v-if="data.item.creator"
              class="d-block text-nowrap selection-group d-flex align-items-center"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                :to="{ name: 'admin-user-view', params: { id: data.item.creator_info.id } }"
              >
                {{ data.item.creator_info ? data.item.creator_info.name : '---' }}
              </b-link>
              <span
                v-b-tooltip.hover.focus.v-secondary
                title="前往管理者"
                class="selection-btn-icon-show"
              >
                <b-img
                  src="/dashboard/admin/images/table/external-link.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </span>
            </div>

            <div
              v-else
              class="text-nowrap"
            >
              <small class="text-muted">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 上次更新 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 新增時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitRemark(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="備註留言"
                src="/dashboard/admin/images/table/chat.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-if="data.item.system_order_id"
              class="actions-link-btn mr-25"
              @click="linkOrderView(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                src="/dashboard/admin/images/table/order.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <remark-modal
      v-if="selected"
      ref="orderRemarkExplorerModal"
      remark-explorer-id="orderRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="refetchTable"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div class="mb-1" />
      </template>
    </remark-modal>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BAvatar, BMedia, BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

// Component
import { useQuotaList, useQuotaSetting } from '../useQuota'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BBadge,
    BMedia,
    BRow,
    BCol,
    BImg,
    BLink,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (解析)類型
    resolveType(item) {
      if (item.system_order_id) {
        return {
          text: '訂單',
          variant: 'light-primary',
        }
      }

      if (item.creator) {
        return {
          text: '手動',
          variant: 'light-warning',
        }
      }

      return {
        text: '系統',
        variant: 'light-info',
      }
    },

    // (觸發)備註
    onSubmitRemark(item) {
      const resolveArray = []
      resolveArray.push(`quota-${item.id}`)
      if (item.system_order_id) resolveArray.push(`order-${item.order_info.id}`)
      if (item.customer_info) resolveArray.push(`customer-${item.customer_info.id}`)
      this.keyArray = resolveArray
      this.selected = JSON.parse(JSON.stringify(item))
      setTimeout(() => { this.$refs.orderRemarkExplorerModal.getData() }, 200)
    },

    // (前往)訂單詳情
    linkOrderView(item) {
      this.$router.push({
        name: 'admin-order-view',
        params: {
          id: item.order_info.id,
        },
      })
    },

    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
        // return
      }
    },
  },
  setup() {
    // 註冊模組
    const QUOTA_ADMIN_STORE_MODULE_NAME = 'admin-quota'

    if (!store.hasModule(QUOTA_ADMIN_STORE_MODULE_NAME)) store.registerModule(QUOTA_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(QUOTA_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(QUOTA_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      searchType,
      searchProductType,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,
      getQuotaListData,
    } = useQuotaList()

    const {
      ui,
      resolveProductType,
      productDictionary,
      productTypeOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useQuotaSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      avatarText,
      productDictionary,
      productTypeOptions,
      resolveProductType,
      useAlertToast,
      dateConfig2,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      searchType,
      searchProductType,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      getQuotaListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss" scoped>
.point-price {
  font-size: 14px
}
.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}

.item-description-email {
  // background-color: yellow;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}
</style>
